$chat-button-bg-color: #ce0084 !default;
$chat-invite-bg-color: #f2f2f2 !default;
$chat-invite-buttons-bg-color: #fff;
$chat-invite-border-color: #ff53ae !default;
$chat-invite-border-width: 3px !default;
$chat-box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .5) !default;
$chat-invite-accept-text-color: $chat-invite-border-color !default;
$chat-invite-reject-text-color: #333 !default;
$chat-text-color: #666 !default;
$chat-font-size: .875rem !default;
$chat-invite-triangle-height: 16px !default;
$chat-invite-triangle-width: 28px !default;
$chat-line-height: 1.5 !default;
$chat-button-padding-h: .7em !default;
$chat-button-padding-v: .35em !default;
$chat-button-margin: $chat-button-padding-v !default;
$chat-button-peek: $chat-button-padding-h !default;
$chat-button-transition-duration: .3s !default;
$chat-invite-transition-duration: .25s !default;
$chat-z-index: 502 !default;
$chat-min-breakpoint: 768px !default;
$chat-max-breakpoint: $chat-min-breakpoint - 1;
$chat-font-family: 'RSA Sans' !default;

.chat-button,
.chat-invite {
  box-shadow: $chat-box-shadow;
  font-family: $chat-font-family;
  font-size: $chat-font-size;
  line-height: $chat-line-height; }

.chat-button {
  background-color: $chat-button-bg-color;
  bottom: $chat-button-margin;
  color: #fff;
  left: $chat-button-margin;
  padding: $chat-button-padding-v $chat-button-padding-h;
  position: fixed;
  text-decoration: none;
  z-index: $chat-z-index;

  &:hover {
    color: #fff;
    cursor: pointer; }

  &::before {
    content: '\e7d7';
    font-family: 'RSA Icons'; }

  @media (max-width: $chat-max-breakpoint) {
    &::before {
      margin-right: .5em; } }

  @media (min-width: $chat-min-breakpoint) {
    bottom: auto;
    left: auto;
    padding-right: $chat-button-peek * 2;
    right: 0;
    top: 50%;
    transform: translateX($chat-button-peek);
    transition: $chat-button-transition-duration transform;

    &:hover {
      transform: translateX(0);
      transition-duration: $chat-button-transition-duration / 2; }

    &::before {
      display: block;
      font-size: 2em;
      line-height: $chat-line-height * $chat-font-size;
      margin-bottom: .3em;
      margin-top: 5px;
      text-align: center; } } }

.chat-invite {
  background-color: $chat-invite-bg-color;
  border: $chat-invite-border-width solid $chat-invite-border-color;
  bottom: calc(#{$chat-font-size * $chat-line-height} + #{$chat-button-padding-v * 2} + #{$chat-invite-triangle-height * 2});
  color: $chat-text-color;
  display: flex;
  flex-flow: row wrap;
  font-weight: bolder;
  max-width: 300px;
  opacity: 0;
  position: fixed;
  transition: opacity $chat-invite-transition-duration ease-in, transform $chat-invite-transition-duration ease-in, z-index 0s ease-in $chat-invite-transition-duration;
  width: calc(100vw - 20px);
  z-index: -$chat-z-index !important; // sass-lint:disable-line no-important

  @media (max-width: $chat-max-breakpoint) {
    left: $chat-button-margin !important; // sass-lint:disable-line no-important
    top: auto !important; // sass-lint:disable-line no-important

    &:not(&--visible) {
      transform: translateX(0) translateY(20%); } }

  &--visible {
    $timing: cubic-bezier(.215, .61, .355, 1);

    opacity: 1;
    transform: translateX(0) translateY(0);
    transition: opacity $chat-invite-transition-duration * 2 $timing, transform $chat-invite-transition-duration * 2  $timing, z-index 0s $timing 0s;
    z-index: $chat-z-index + 1 !important; } // sass-lint:disable-line no-important

  &::before,
  &::after {
    border-style: solid;
    border-width: $chat-invite-triangle-height $chat-invite-triangle-width / 2 0 $chat-invite-triangle-width / 2;
    content: '';
    left: $chat-font-size;
    position: absolute; }

  &::before {
    border-color: $chat-invite-border-color transparent;
    bottom: -$chat-invite-triangle-height;
    z-index: $chat-z-index; }

  &::after {
    border-color: $chat-invite-buttons-bg-color transparent;
    bottom: -$chat-invite-triangle-height + $chat-invite-border-width * 2;
    z-index: $chat-z-index + 1; }

  &__text {
    background-color: $chat-invite-bg-color;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 1.25em; }

  &__accept,
  &__reject {
    background-color: $chat-invite-buttons-bg-color;
    cursor: pointer;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 1em 1.25em;
    text-transform: uppercase; }

  &__accept {
    color: $chat-invite-accept-text-color;
    text-align: right; }

  &__reject {
    color: $chat-invite-reject-text-color;
    text-align: left; }


  @media (min-width: $chat-min-breakpoint) {
    bottom: auto;
    left: auto !important; // sass-lint:disable-line no-important
    right: $chat-font-size * 5.5;
    top: 50% !important; // sass-lint:disable-line no-important
    width: 275px;

    &:not(&--visible) {
      transform: translateX(20%) translateY(0); }

    &::before,
    &::after {
      bottom: auto;
      top: $chat-font-size; }

    &::before {
      border-color: transparent transparent transparent $chat-invite-border-color;
      border-width: $chat-invite-triangle-width / 2 0 $chat-invite-triangle-width / 2 $chat-invite-triangle-height;
      left: 100%; }

    &::after {
      border-color: transparent transparent transparent $chat-invite-bg-color;
      border-width: $chat-invite-triangle-width / 2 0 $chat-invite-triangle-width / 2 $chat-invite-triangle-height;
      left: auto;
      right: -$chat-invite-triangle-height + $chat-invite-border-width * 1.75; } } }

// @import 'variants/silverton'
